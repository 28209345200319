.a__input {
  padding: {
    bottom: 7px;
  }

  .input {
    input {
      height: 46px;
      min-height: 46px;
      border: none;
      border-radius: 4px !important;
      background: #fff;

      &:focus {
        background: #fff;
        box-shadow: 0 0 0 3px rgba(#fa6681, 0.7);
      }

      &:active {
        background: #fff;
        box-shadow: 0 0 0 3px rgba(#fa6681, 0.7);
      }
    }

    &.outline {
      box-shadow: 0 0 30px #e9ecef;
    }

    &.disabled {
      opacity: 0.8;
    }

    &.dark {
      input {
        border: none;
        background: #edeced;

        &:focus {
          background: #fff;

          box-shadow: 0 0 0 3px rgba(#fa6681, 0.7);
        }

        &:active {
          background: #fff;

          box-shadow: 0 0 0 3px rgba(#fa6681, 0.7);
        }
      }

      &.disabled {
        opacity: 0.4;
      }

      :placeholder-shown {
        font-weight: bold;
        color: #787878;
      }

      ::-webkit-input-placeholder {
        font-weight: bold;
        color: #787878;
      }

      :-moz-placeholder {
        font-weight: bold;
        color: #787878;
        opacity: 1;
      }

      ::-moz-placeholder {
        font-weight: bold;
        color: #787878;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: #787878;
      }

      font-weight: bold;
    }
  }
}