.a__news-edit-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .b__messages {
    padding: 10px 0 20px;
  }

  .b__actions {
    button:nth-child(2) {
      margin: {
        left: 10px !important;
      }
    }
  }
}
