@import './../_values';

.gc__image {
  background: {
    color: #efefef !important;
  }

  width: 250px;
  height: 354px;
  border-radius: 4px;

  &.fluid {
    width: 100%;
    min-width: 250px;
    height: auto;
    min-height: 354px;
  }
}
