.user-nickname {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-fee-delete {
  outline: none;
  border: 1px solid #e6dede;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  background:#eaaf00;

  &:hover,
  &:focus {
    color: #fff;
    background: #d19c00;
  }

  i.icon {
    margin: 0 !important;
  }
}
