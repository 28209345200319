.c__after-title {
  margin: {
    bottom: 20px;
  }
}

.c__before-title {
  margin: {
    bottom: 20px;
  }
}

.c__cant-click {
  pointer-events: none;
}