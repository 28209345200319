.a__checkbox {
  .ui.checkbox {
    display: flex;
    align-items: center;

    label::before {
      top: 0;
    }
  }
}
