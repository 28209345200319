.thumb_wrapper_aside {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-top: 10px;
  border-radius: 4px;

  .thumb_outer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 121px;
    margin: 10px 5px 0;

    .upload {
      pointer-events: none;
      opacity: 0.8;
    }

    .thumb_image_outer {
      position: relative;
      display: block;
      min-width: 0;
      border-radius: 4px;

      .loader_outer {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;

        .loader_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      .thumb_image {
        width: 100px;
        height: 100px;
        background-color: white;
        object-fit: contain;
        object-position: 0 50;
        border: solid 1px #cccc;
      }

      .w-icon {
        position: relative;
        z-index: 10;
        cursor: pointer;

        .crop_icon {
          position: absolute;
          top: 4px;
          left: 3px;
        }

        .close_icon {
          position: absolute;
          top: 4px;
          right: 3px;
        }

        i.inverted.bordered.icon,
        i.inverted.circular.icon {
          background-color: rgba(#000, 0.3) !important;
        }

        &:hover {
          opacity: 0.7;
        }
      }
      .w__item-text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4183c4;
        .w-icon {
          padding: {
            right: 7px;
            left: 7px;
          }
        }
      }
    }
  }
}
