.semantic-textarea {
  padding: {
    bottom: 7px;
  }

  textarea {
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 100%;
    outline-style: none;
    resize: vertical;

    &.fluid {
      width: 100%;
    }

    &.outline {
      box-shadow: 0 0 30px #e9ecef;
    }

    &.diabled {
      opacity: 0.8;
      cursor: not-allowed;

      &:focus {
        box-shadow: none;
      }

      &:active {
        box-shadow: none;
      }
    }

    &:focus {
      background: {
        color: #fff;
      }

      box-shadow: 0 0 0 3px rgba(#EAAF00, 0.7);
    }

    &:active {
      background: {
        color: #fff;
      }

      box-shadow: 0 0 0 3px rgba(#EAAF00, 0.7);
    }
  }

  &.dark {
    textarea {
      background: {
        color: #edeced;
      }

      &.diabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:focus {
          background: {
            color: #edeced;
          }
        }

        &:active {
          background: {
            color: #edeced;
          }
        }
      }

      &:focus {
        background: {
          color: #fff;
        }
      }

      &:active {
        background: {
          color: #fff;
        }
      }
    }

    textarea::placeholder {
      color: #787878;
      font-weight: bold;
    }

    textarea:-moz-placeholder {
      color: #787878;
      font-weight: bold;
      opacity: 1;
    }

    textarea::-moz-placeholder {
      color: #787878;
      font-weight: bold;
      opacity: 1;
    }

    textarea:placeholder-shown {
      color: #787878;
      font-weight: bold;
    }

    ::-webkit-input-placeholder {
      color: #787878;
      font-weight: bold;
    }

    :-ms-input-placeholder {
      color: #787878;
      font-weight: bold;
    }
  }

  &.normal {
    textarea::placeholder {
      opacity: 0.6;
    }

    textarea:-moz-placeholder {
      opacity: 0.6;
    }

    textarea::-moz-placeholder {
      opacity: 0.6;
    }

    textarea:placeholder-shown {
      opacity: 0.6;
    }

    ::-webkit-input-placeholder {
      opacity: 0.6;
    }

    :-ms-input-placeholder {
      opacity: 0.6;
    }
  }
}
