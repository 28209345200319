@import './../_values';

@mixin _make_placeholder() {
  color: #555;
}

@mixin gc__placeholder() {
  ::-webkit-input-placeholder {
    @include _make_placeholder();
  }

  :-moz-placeholder {
    @include _make_placeholder();
  }

  ::-moz-placeholder {
    @include _make_placeholder();
  }

  :-ms-input-placeholder {
    @include _make_placeholder();
  }
}
