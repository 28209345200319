@import './../../../styles/values';
@import './../../../styles/mixin';

.a__before_routes {
  display: flex;
  min-height: 100vh;
  height: 100%;
  color: $app__black-color;
  padding: 50px;

  // @include gc__bg_gradient($app__main-color, 60deg);
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}
