@import './values';

@mixin gc__bg_gradient(
  $color: $before-color_main,
  $angle: 45deg,
  $amount: 40%
) {
  background: linear-gradient($angle, $color, adjust-hue($color, $amount)) fixed;
}

//メディアクエリ呼び出し用のmixin
@mixin gc__mq_down($breakpoint: md) {
  @media #{map-get($bp_down, $breakpoint)} {
    @content;
  }
}

// メディアクエリ呼び出し用のmixin
@mixin gc__mq_up($breakpoint: md) {
  @media #{map-get($bp_up, $breakpoint)} {
    @content;
  }
}
