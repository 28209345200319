.a__gc-dropdown {
  &.disabled {
    cursor: not-allowed;
  }

  .gc__dropdown {
    .ui.dropdown {
      &.disabled {
        opacity: 0.5 !important;
      }
    }
  }
}

