.a__users-main {
  .b__sub {
    display: flex;
    padding: {
      bottom: 20px;
    }

    .b__search {
      flex: 0.5;
    }

    .b__create {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
    }
  }

  .b__caution {
    padding: 0 30px;
  }

  .b__users {
    position: relative;

    &-create {
      position: absolute;
      top: 8px;
      right: 240px;
      
      button {
        position: relative;
        z-index: 1;
        border: none;
        outline: none;
        border-radius: 0.28rem;
        height: 35px;
        padding: 0.25rem 1.25rem;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        background: #eaaf00;

        &:hover {
          background: #d19c00;
        }
      }
    }
    .rt-tbody {
      overflow-y: scroll;
      max-height: 400px;
    }
  }
}
