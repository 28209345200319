body {
  color: $app__black-color;

  background: {
    color: #fefefe;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    min-height: 30px;
    margin: 0;
  }

  h2 {
    min-height: 30px;
  }

  h3 {
    min-height: 23px;
  }

  div,
  span,
  p {
    margin: 0;
    font-size: #{$app__font-size}px;
    line-height: $app__line-height;
  }

  a {
    color: $app__link-color;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: darken($app__link-color, 5%);
      text-decoration: underline;
    }
  }

  button {
    color: $app__main-color;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: darken($app__main-color, 5%);
      text-decoration: underline;
    }
  }

  ::selection {
    background: rgba($app__main-color, 0.6);
    color: $app__white-color;
  }

  ::-moz-selection {
    background: rgba($app__main-color, 0.6);
    color: $app__white-color;
  }

  // include
  @include gc__placeholder();
}
