@import './../../../styles/values';

.gc__header {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 0 1px #e9ecef;

  ul {
    display: flex;
    width: 100%;
    align-items: flex-end;
    padding: 0;
    margin: 0;

    li {
      font-size: 14px;
      font-weight: bold;
      list-style: none;
      margin: {
        left: auto;
      }

      .name {
        cursor: pointer;

        .icon.dropdown {
          margin: 0 0 0 7px;
        }

        &:hover {
          .icon.dropdown {
            color: $app__main-color;
            transition-duration: 0.3s;
          }
        }
      }
    }
  }
  background: {
    color: $app__white-color;
  }
}
