.a__mail-target {
  .w__field {
    display: flex;
  }

  .target {
    display: flex;
    align-items: center;

    .ui.checkbox input:checked ~ label:after {
      background: #787878 !important;
    }
  }
  .target:first-child {
    margin-right: 20px;
  }
  
  .target.is_disabled {
    .ui.checkbox input.hidden+label {
      cursor: not-allowed !important;
    }
  }
}
