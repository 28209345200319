.a__news-form {
  .b__form {
    display: flex;

    .b__input {
      textarea {
        height: 250px;
        resize: vertical;
      }

      .period {
        .gc__input {
          input {
            font-size: 16px;
          }
        }
      }

      .release-setting {
        display: flex;
        label {
          display: flex;
          align-items: center;
          font-size: 14px;
          .gc__input {
            padding-bottom: 0;
            display: flex;
            align-items: center;
            margin-right: 8px;
          }
        }
        .private {
          margin-left: 32px;
        }
      }

      .period {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;

        .gc__input {
          width: 45%;
          padding-bottom: 0;
        }

        .unit {
          margin-top: 10px;
        }

        .form__error {
          width: 508px;
        }
      }
    }
  }
}
