.a__fishing-date {

  .column {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 8px;

    span {
      padding-left: 3px;
    }
  }

  .ui.dropdown {
    width: 100%;

    div.text {
      top: 6px;
    }
  }

  @media screen and (min-width: 1224px) {
    .column {
      width: 230px;

      span {
        margin-right: 8px;
      }
    }
  }
}
