.a__label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: bold;
  color: #222;
  margin-bottom: 4px;

  .badge {
    width: 28px;
    height: 16px;
    font-weight: bold;
    text-align: center;
    background: #d8d8d8;
    line-height: 10px;
    margin-bottom: 1px;
    margin-left: 10px;
    border-radius: 3px;

    span {
      color: #111;
      font-size: 10px;
    }
  }

  .badge.required {
    background: #ef6266;

    span {
      color: #fff;
    }
  }
}
