@import './../_values';

//メディアクエリ呼び出し用のmixin
@mixin gc__mq_down($breakpoint: md) {
  @media #{map-get($bp_down, $breakpoint)} {
    @content;
  }
}

// メディアクエリ呼び出し用のmixin
@mixin gc__mq_up($breakpoint: md) {
  @media #{map-get($bp_up, $breakpoint)} {
    @content;
  }
}
