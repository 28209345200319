@import './../_values';

.gc__form {
  display: flex;

  .gc__form-card {
    min-width: 588px;
    padding: 20px 40px;
    border-radius: 4px;
    box-shadow: 0 0 30px #e9ecef;
    background: {
      color: $gc__white-color;
    }

    &.modal {
      min-width: 400px
    };

    .gc__form-label {
      font-size: 14px;
    }
  }
}
