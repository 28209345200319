.gc__input {
  .ui.input {
    &.disabled {
      cursor: not-allowed;
    }

    input[type="date"]::-webkit-outer-spin-button,
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-clear-button {
      display: none;
    }
  }
}