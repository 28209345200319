@import './../_values';

.gc__hidden {
  display: none !important;
}

.gc__visible {
  display: block;
}

.gc__disabled {
  opacity: 0.5;
  pointer-events: none;
}

.gc__hidden_more_lg {
  @media screen and (max-width: 991px) {
    display: flex !important;
  }

  @media screen and (min-width: 992px) {
    display: none !important;
  }
}

.gc__hidden_more_md {
  @media screen and (max-width: 767px) {
    display: flex !important;
  }

  @media screen and (min-width: 768px) {
    display: none !important;
  }
}
