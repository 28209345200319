.a__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.45);
}

.app__modal {
  overflow: auto;
  background-color: #fff;
  outline: none;

  .w__close {
    position: relative;
    z-index: 9999;
    width: 18px;
    cursor: pointer;
    outline: none;

    img {
      width: 18px;
    }
  }

  .b__body {
    width: 100%;
    padding: {
      top: 20px;
      bottom: 20px;
    }
  }

  &.xs {
    width: 40vw;
    padding: {
      right: 36px;
      left: 36px;
    }

    .w__close {
      top: 16px;
      left: -22px;
    }
  }

  &.sm {
    width: 50vw;
    padding: {
      right: 35px;
      left: 35px;
    }

    .w__close {
      top: 13px;
      left: -22px;
    }
  }

  &.md {
    width: 60vw;
    padding: {
      right: 40px;
      left: 40px;
    }

    .w__close {
      top: 15px;
      left: -27px;
    }
  }

  &.lg {
    width: 75vw;
    padding: {
      right: 40px;
      left: 40px;
    }

    .w__close {
      top: 16px;
      left: -25px;
    }
  }

  &.xl {
    width: 95vw;
    padding: {
      right: 45px;
      left: 45px;
    }

    .w__close {
      top: 15px;
      left: -30px;
    }
  }

  &.full {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 10px;
    background-color: white;

    .w__close {
      top: 5px;
      left: 5px;
    }
  }

  @include mq_down() {
    &.xs,
    &.sm,
    &.md,
    &.lg,
    &.xl {
      width: 95vw;
      padding: {
        top: 5px;
        right: 24px;
        left: 24px;
      }

      .w__close {
        top: 10px;
        left: -9px;
      }
    }
  }
}
