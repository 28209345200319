.a__exhibition-form {
  display: flex;

  .form__error {
    padding-top: 4px;
    color: #d95858;
    font-size: 13px;
  }

  .w__main {
    width: 640px;
    padding: 20px 40px;
    background: #fff;
    box-shadow: 0 0 30px #e9ecef;
  }

  .w__title {
    display: flex;
    justify-content: center;
    padding: 56px 0 32px;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .w__field {
    padding-bottom: 20px;

    .ui.input {
      width: 100%;

      input {
        height: 40px;
        min-height: auto;
        outline: 0;
        border: 1px solid rgba(34, 36, 38, 0.15) !important;
        outline: 0;
      }
      input:active {
        box-shadow: none;
      }
      input:focus {
        box-shadow: none;
        border-color: #85b7d9 !important;
      }
    }

    .ui.dropdown {
      min-height: 42px;
      padding: 0;

      input {
        width: 100%;
        min-height: auto;
      }

      div.text {
        position: absolute;
        left: 14px;
      }
    }

    .a__input {
      .ui.basic.label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 40px;
        color: #222;
        font-size: 1em;
        border-left: 0;
      }
    }

    .gc__dropdown {
      width: 100%;
      min-height: auto;
      padding-bottom: 8px;
    }

    .a__gc-dropdown {
      width: 100%;
    }

    .a__textarea {
      width: 100%;

      textarea {
        width: 100%;
        border: 1px solid rgba(34, 36, 38, 0.15);
        resize: none;
        box-shadow: none;
      }
      textarea:focus {
        border-color: #85b7d9;
      }
    }
  }

  .stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em 1.25em;
  }

  .w__field.dummy-user {
    .a__gc-dropdown {
      width: 260px;
    }
  }

  .w__field.f {
    .ui[class*='right labeled'].input > input {
      border-radius: 0 !important;
    }
    .ui[class*='right labeled'].input > input {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }

  .w__field.row {
    padding-bottom: 0;
    // 1224px以上
    @media screen and (min-width: 1224px) {
      display: flex;
    }
  }

  .w__field.yen {
    .ui[class*='right labeled'].input > input {
      border-radius: 0 !important;
    }

    .a__input {
      .ui.input > input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      .ui.basic.label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 40px;
        color: #222;
        font-size: 1em;
        border-right: 0;
        border-left: 1px solid rgba(34, 36, 38, 0.15);
      }
    }
  }

  .w__field.agreement {
    padding-bottom: 8px;

    .a__label {
      margin-bottom: 8px;
    }

    a {
      color: #85b7d9;
      font-weight: normal;
    }
  }

  .image_field_base_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .image_field_text {
    font-size: 14px;
    text-align: center;
  }

  .action-buttons {
    .submit.ui.button {
      margin-left: 10px !important;
    }
  }

  .right-buttons {
    margin-left: 40px;

    .delete.ui.button {
      margin-bottom: 20px !important;
      color: #fff;
      background: #d95858;
    }
  }
}
