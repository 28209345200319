.w__is-nature-radio {

  .nature {
    display: flex;
    align-items: center;
  }

  label {
    padding-left: 8px;
    font-size: 14px;
  }
}
