.a__sales-main {
  .b__sub {
    display: flex;
    padding: {
      bottom: 20px;
    }

    .b__search {
      flex: 0.5;
    }

    .b__create {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
    }
  }
  .b__sales {
    .rt-tbody {
      overflow-y: scroll;
      max-height: 400px;
    }
  }
}
