@import './../../../styles/values';
@import './../../../styles/mixin';

.a__reset_password_thank {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  padding: {
    right: 10%;
    left: 10%;
  }

  @include gc__mq_down('sm') {
    justify-content: center;
    padding: {
      right: 3%;
      left: 3%;
    }
  }
}
