@import './../../../styles/_values';

.a__after_routes {
  display: flex;

  .b__after_routes_sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 220px;
    height: 100vh;
  }

  .b__after_routes_main {
    position: absolute;
    left: 221px;
    display: flex;
    width: calc(100vw - 220px);
    flex-direction: column;
    color: $app__black-color;
    background: {
      color: lighten($app__main-color, 30%);
    }

    .b__header {
      position: fixed;
      z-index: 200;
      top: 0;
      width: calc(100vw - 220px);
      height: 55px;
    }

    .b__main {
      position: absolute;
      z-index: 0;
      top: 56px;
      width: calc(100vw - 220px);
      height: auto;
      flex: 1;
      padding: 0 0 40px;

      .a__users,
      .a__bans,
      .a__sales,
      .a__mails,
      .a__news,
      .a__dummy-sales {
        &-main {
          .b__title,
          .b__sub {
            padding: 20px 30px 0;
          }
        }

        &-create,
        &-edit {
          padding: 20px 30px 40px;
        }
      }
    }
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}
