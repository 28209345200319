.gc__input,
.gc__credit-card-input {
  padding: {
    bottom: 7px;
  }

  .input {
    input {
      height: 46px;
      min-height: 46px;
      border: none;
      border-radius: 4px !important;
      background: {
        color: $form__white-color;
      }

      &:focus {
        background: {
          color: $form__white-color;
        }

        box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
      }

      &:active {
        background: {
          color: $form__white-color;
        }

        box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
      }
    }

    &.outline {
      box-shadow: 0 0 30px #e9ecef;
    }

    &.disabled {
      opacity: 0.8;
    }

    &.dark {
      input {
        border: none;
        background: {
          color: $form__dark-color;
        }

        &:focus {
          background: {
            color: $form__white-color;
          }

          box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
        }

        &:active {
          background: {
            color: $form__white-color;
          }

          box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
        }
      }

      &.disabled {
        opacity: 0.4;
      }

      :placeholder-shown {
        font-weight: bold;
        color: $form__dark_placeholder-color;
      }

      ::-webkit-input-placeholder {
        font-weight: bold;
        color: $form__dark_placeholder-color;
      }

      :-moz-placeholder {
        font-weight: bold;
        color: $form__dark_placeholder-color;
        opacity: 1;
      }

      ::-moz-placeholder {
        font-weight: bold;
        color: $form__dark_placeholder-color;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: $form__dark_placeholder-color;
      }

      font-weight: bold;
    }
  }
}
