.rc-notification {
  top: 5px !important;

  .rc-notification-notice {
    width: 95vw;
    padding: 11px 20px 11px 10px;
    border: none;

    .rc-notification-notice-close {
      top: -6px;
      right: 9px;
      font-size: 20px;
      font-weight: 100;
      line-height: 1.7 !important;
      color: white !important;
      opacity: 1;

      span {
        &.rc-notification-notice-close-x::after {
          font-family: unset !important;
          font-size: 20px !important;
        }
      }

      &:hover {
        color: white !important;
        opacity: 0.85;
      }
    }

    .rc-notification-notice-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
