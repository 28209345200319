.a__mails-form {
  .b__form {
    display: flex;

    .gc__textarea.dark {
      padding-bottom: 0;

      textarea {
        height: 250px;
        resize: vertical;
      }
      .form__error {
        margin-bottom: 7px;
        padding-top: 0;
      }
    }
  }
}
