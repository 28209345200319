@import './../../../../styles/values';

.l__header-dropdown {
  .ui {
    &.dropdown {
      .menu > .header {
        color: #787878;
        text-transform: none;
      }

      .item {
        color: $app__black-color;
        font-size: 13px;
      }
    }
  }
}
