@import './../../../styles/values';

.gc__sidebar {
  position: fixed;
  z-index: 200;
  width: 220px;
  height: 100%;

  .sidebar {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: {
      color: $app__accent-color;
    }

    ul {
      padding: 0 !important;
      margin: 0 !important;
      list-style: none;

      li {
        cursor: pointer;

        &:hover {
          background: {
            color: darken($app__accent-color, 2.5%);
          }
        }

        a {
          padding: {
            top: 10px;
            right: 20px;
            bottom: 10px;
            left: 20px;
          }

          display: inline-block;
          width: 100%;
          height: 100%;
          color: white !important;
          text-decoration: none;

          i.icon,
          i.icons {
            height: 1.1em;
            font-size: 1.1em;
          }

          .title {
            padding: {
              left: 20px;
            }

            color: white !important;
          }
        }

        &.logo {
          height: 170px;

          .image {
            width: 60%;
            height: auto;
            background: {
              color: $app__white-color;
            }
          }

          .w__logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        &.active {
          background: {
            color: darken($app__accent-color, 5%);
          }
        }
      }
    }
  }
}
