@import '../../_value';

.gc__table {
  .b__table {
    border: {
      radius: 4px;
    }

    .gc__nodata {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 12px;
      color: $table__nodata-text-color;
      background: {
        color: rgba(white, 0.6);
      }
    }

    .gc__loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: {
        color: rgba(white, 0.6);
      }
    }

    .noclick {
      pointer-events: none;
      opacity: 0.85;
    }

    .b__sort {
      cursor: pointer !important;
      outline: none;

      .icon {
        margin: 0;

        &.triangle.up {
          position: absolute;
          bottom: 30px;
          color: $table__sort-default-color !important;

          &.sortKey.asc {
            color: $table__main-color !important;
          }
        }

        &.triangle.down {
          position: absolute;
          bottom: 25px;
          color: $table__sort-default-color !important;

          &.sortKey.desc {
            color: $table__main-color !important;
          }
        }
      }
    }
  }

  .outline {
    box-shadow: 0 0 30px #e9ecef;
  }

  .b__pagination {
    display: flex;
    justify-content: flex-end;

    &.hidden {
      display: none;
    }

    &.center {
      justify-content: center;
    }

    &.left {
      justify-content: flex-start;
    }
  }
}
