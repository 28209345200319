@import './../../../styles/values';
@import './../../../styles/mixin';

.a__reset_password {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @include gc__mq_down() {
    flex-direction: column;
  }

  .bf__form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: {
      right: 10%;
      left: 10%;
    }

    @include gc__mq_down('sm') {
      justify-content: center;
      padding: {
        right: 3%;
        left: 3%;
      }
    }

    @include gc__mq_down() {
      justify-content: center;
    }

    .b__messages {
      padding: {
        bottom: 20px;
      }
    }

    .b__form {
      width: 100%;

      .w__actions {
        padding: {
          top: 5px;
        }
      }

      @include gc__mq_up('lg') {
        width: 80%;
      }

      @include gc__mq_up('xl') {
        width: 70%;
      }
    }
  }
}
